<template>
  <div class="pagePadding">
    <div>
      <span class="pageBtn finger btnReset" @click="back">返回</span>
    </div>
    <div class="nav">
      <FormLine :list="list" :nowIndex="nowIndex"></FormLine>
    </div>
    <div class="tabelDiv">
      <Table :productList="listColumns" :productData="listData" :isLoad="isLoad" :loading="isLoad">
        <div slot="summary" class="summary">
          <div>
            <span style="margin-right: 20px;"
              >总数量：<span class="color389">{{ detailsInfo.total_number }}</span></span
            >
            <span>合计金额：</span>
            <span class="color389">¥{{ detailsInfo.total_amount }}</span
            >
          </div>
        </div>
      </Table>
    </div>
    <div class="other">其它信息</div>
    <div class="fromDiv">
      <div class="formLine">
        <FormLabel label="订单号"></FormLabel>
        <div class="allWidth fl">{{ detailsInfo.order_number }}</div>
      </div>
      <div class="formLine borderTopNone">
        <FormLabel label="下单时间"></FormLabel>
        <div class="allWidth fl">{{ $moment(detailsInfo.create_time * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
      </div>
      <div class="formLine borderTopNone">
        <FormLabel label="备注信息"></FormLabel>
        <div class="allWidth fl nowrap">{{ detailsInfo.note ? detailsInfo.note : '无' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Table from '@/components/table'
import FormLine from '@/components/formLine'
import FormLabel from '@/components/formLabel'
export default {
  name: 'purchaseReturnsDetailsConsign',
  components: {
    Table,
    FormLine,
    FormLabel,
  },
  computed: {},
  mounted() {
    this.id = this.$route.query.id
    this.queryDetails(this.id)
  },
  data() {
    return {
      deleteStatus: false,
      logisTitle: '新增物流', // 物流弹窗
      id: '',
      isLoad: true,
      nowIndex: 0,
      list: [
        {
          title: '待审核',
          value: '1',
        },
        {
          title: '待发货',
          value: '2',
        },
        {
          title: '已完成',
          value: '3',
        },
      ],
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 200,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 200,
        },
        {
          title: '批号',
          key: 'batch_number',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '序列号',
          key: 'serial_number',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '单价',
          align: 'center',
          width: 200,
          render: (h, param) => {
            return h('div', [h('span', param.row.unit_price ? '¥' + param.row.unit_price : 0)])
          },
        },
        {
          title: '数量',
          key: 'quantity',
          align: 'center',
          width: 200,
        },
        {
          title: '小计',
          key: 'subtotal',
          align: 'center',
          width: 200,
          render: (h, param) => {
            return h('div', [h('span', param.row.subtotal ? '¥' + param.row.subtotal : '0')])
          },
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 200,
        },
      ],
      listData: [],
      detailsInfo: {},
    }
  },
  methods: {
    // 查询详情
    queryDetails(id) {
      this.isLoad = true
      this.$http.get(this.$apiConsign.pruchaseReturnDetail, { id: id }, true).then(res => {
        this.isLoad = false
        this.detailsInfo = res.data
        this.listData = res.data.purchase_return_line
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].value == this.detailsInfo.purchase_return_state) {
            this.nowIndex = i
          }
        }
      })
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped lang="less">
.nav {
  margin: 12px 0 23px 0;
}
.other {
  height: 25px;
  font-size: 18px;
  font-weight: 600;
  color: #525b6d;
  line-height: 25px;
  margin: 20px 0;
}
.item {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  span {
    width: 80px;
    color: #000;
    text-align: justify;
    height: 32px;
    line-height: 32px;
  }
  span::after {
    content: '';
    display: inline-block;
    padding-left: 100%;
  }
}
.iviewIptWidth373 {
  width: 373px !important;
}
</style>
